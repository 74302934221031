<template>
  <footer class="footer" :class="`footer--theme-${theme}`">
    <div class="footer__content">
      <!-- <FooterContent
        v-if="customFooterContent.length"
        :value="customFooterContent"
        class="footer__custom-content container"
      /> -->
      <div class="footer__container container">
        <router-link to="/" @click.native="scrollToTop">
          <img
            v-if="theme == 'default'"
            class="footer__logo"
            :src="logo"
            width="150"
            :alt="item.name"
          />
        </router-link>
        <div class="footer__menu footer__clickable">
          <!-- <b class="footer__menu-title">{{ $t('menu') }}</b> -->
          <template v-for="(item, index) in navigation">
            <ActionComponent
              v-if="$path('action.name', item) !== $path('name', $route)"
              class="footer__menu-action"
              :class="{ 'footer__menu-action--empty': !item.action }"
              v-bind="item"
              :key="index"
            />
            <template v-if="item.items && item.items.length">
              <ActionComponent
                v-for="(nested, ni) in item.items"
                class="footer__menu-action footer__menu-action--indent"
                v-bind="nested"
                :key="index + '_' + ni"
              />
            </template>
          </template>
        </div>
        <so-me-footer v-if="someCustomField.length !== 0" :value="someCustomField" />
        <div class="footer__policy footer__links footer__clickable">
          <a
          href="https://malling.no/forbehold"
          target="_blank">Forbehold</a>
          
          <router-link :to="`/api/legal/privacy/project/${item.id}`" target="_blank">
            {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
          </router-link>

          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>

          <div class="footer__copyright">{{ year }} &copy; {{ item.name }}</div>
        </div>

        <div class="footer__bottom" :class="{ container: theme == 'compact' }">
          <div :class="{ container: theme == 'default' }">
            <span class="footer__bottom-text"
              >Dette digitale prospektet er utviklet av ORO i samarbeid med Pulse
              Communications</span
            >
            <span>
              {{ $t('pageMadeBy') }}
              <a
                id="https://www.kvass.no"
                :href="`https://www.kvass.no?ref=${currentUrl}`"
                target="_blank"
                >Kvass</a
              >
            </span>
          </div>
        </div>

        <img
          v-if="theme == 'compact'"
          class="footer__logo"
          :src="logo"
          width="200"
          :alt="item.name"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'

export default {
  props: {
    navigation: Array,
    theme: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'compact'].includes(value)
      },
    },
    cookieManager: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    customFooterContent() {
      if (!this.item) return
      return GetCustomField('footer-content', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },
    address() {
      // replaces  ( space- ) with ( , )
      const regex = / -/gi
      if (!this.item.address) return
      return this.item.address.formatted.replace(regex, ',')
    },
    currentUrl() {
      return window.location.href
    },

    logo() {
      //return logo based on primary-color lightness

      if (!this.item.customFields) return

      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (this.item.customFields['footer-logo']) return this.item.customFields['footer-logo'].url
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted

      return API.logo
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },

  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: var(--beige);
  color: var(--footer-text);
  // padding: 4rem 2rem;
  &__clickable {
    font-size: 0.875rem;
    //margin: 0 0 3rem;

    & > button:hover,
    & > a:hover {
      text-decoration: underline;
    }
  }

  &__logo {
    max-width: 160px;
    height: 100%;
    max-height: 60px;
    // margin: 0 0 3rem;
  }

  &__content {
    padding-top: 4rem;
  }

  &__bottom {
    padding-block: 0.5rem;
    padding-top: 1rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 1rem;
      flex-wrap: wrap;
    }
    &-text {
      //text-align: justify;
      text-align: center;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    align-items: center;
  }

  &__copyright {
    a {
      font-weight: bold;
    }
  }

  &__policy {
    display: flex;
    flex-wrap: wrap;
  }

  &__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    &-action {
      cursor: pointer;
      text-transform: uppercase;
      padding: 0.5rem;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &[id='https://www.kvass.no'] {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        width: 100%;
        height: 2px;
        background-color: var(--primary);
      }
    }
  }

  p {
    margin-top: 0;
  }

  &__links {
    display: flex;
    flex-direction: row;
    @include respond-below('phone') {
      align-items: center;
      justify-content: center;
    }

    gap: 1rem;
  }

  /* Themes */
  &--theme {
    &-compact {
      .footer__content {
        padding-block: 3rem;
      }

      .footer__bottom {
        padding-block: 1rem;
      }
    }
  }
}
</style>
