<template>
  <div class="attachments">
    <strong v-if="title">{{ title }}</strong>
    <a
      :href="attachment.url"
      class="attachments__item"
      v-for="attachment in value"
      :key="attachment.key"
      @click.prevent="download(attachment)"
    >
      <FontAwesomeIcon :icon="getIcon(attachment)" size="2x" />
      <div class="attachments__item-label">
        {{ attachment.name }}
      </div>
    </a>
    <div id="attachments" class="scroll-anchor"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DownloadUrl } from '@/utils'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: String,
  },
  computed: {
    ...mapState('Project', ['item']),
  },
  methods: {
    getIcon(item) {
      return [
        { pattern: /application\/pdf/, icon: ['fad', 'file-pdf'] },
        { pattern: /image\/.+/, icon: ['fad', 'file-image'] },
        { pattern: /.+/, icon: ['fad', 'file-alt'] },
      ].find(e => e.pattern.test(item.type)).icon
    },
    download(item) {
      this.$tracker.download('attachment')
      DownloadUrl(item.url, item.name)
    },
  },
}
</script>

<style lang="scss">
.attachments {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: $light-grey;
    }

    &-label {
      opacity: 0.75;
      word-break: break-all;
    }
  }
}
</style>
