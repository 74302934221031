<template>
  <Loader :value="promises.fetch" theme="default" class="project-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BrowserApiMixin from '@/mixins/browser-api'
import { Blocks } from '@kvass/pagebuilder'
import ScrollAnchor from '@kvass/scroll-anchor'
import ProjectComponents from '@/components/Project'

import Hero from '@/components/Hero'

import config from '@/config/project'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),

    config() {
      return config.call(this)
    },
    customComponents() {
      return { Hero, ScrollAnchor, ...ProjectComponents }
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    ScrollAnchor,
    Hero,
    Blocks,
  },
}
</script>

<style lang="scss">
.project-page {
  // class meant to be extended
  .project-h2 {
    font-size: 45px;
    margin-top: 1.5rem;
    margin-bottom: 0rem;
  }

  .header {
    &__text {
      max-width: 1000px;
      margin-right: auto;
      margin-left: auto;
      h1,
      h2 {
        padding: 1.5rem;
        padding-bottom: 0;
        @extend .project-h2;
      }
      p {
        padding: 1.5rem;

        margin: 0 0 1.5rem;
        font-size: 1rem;
        margin-bottom: 0;
        em {
          font-size: 1.2rem;
        }
      }

      p:last-child {
        margin-bottom: 3%;
      }
    }
  }

  .section-cards {
    background-color: var(--beige);
    @include respond-above('phone') {
      padding-bottom: 2rem;
    }

    &__layout {
      flex-direction: column !important;
      gap: 0 !important;

      @include respond-above('phone') {
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: space-between !important;
        gap: 85px !important;
        margin-left: 70px;
        padding-right: 70px;
        padding-top: 35px;
      }
    }

    &__content {
      @include respond-above('phone') {
        width: 30%;
        flex-grow: 1;
      }
    }

    &__image {
      height: 365px;

      .kpb-image__image {
        max-height: 365px;
      }
    }
    &__text {
      padding: 2rem 1.5rem;
      text-align: left;
      margin: 0 auto;
      width: 100%;

      @include respond-above('phone') {
        padding: 0px;
      }
      h2 {
        @extend .project-h2;
        font-size: 1.4rem;

        @media (min-width: 544px) {
          font-size: 1.6rem;
        }
        @include respond-above('phone') {
          font-size: 2rem;
        }
      }
      ul {
        padding-inline-start: 1em;
        margin-bottom: 1.5rem;
        li::marker {
          color: var(--secondary);
          content: '- ';
        }
        p {
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
    }
  }

  .section {
    &__layout {
      flex-direction: column-reverse !important;
      padding-block: 65px;
      align-items: center !important;
      @include respond-above('phone') {
        padding-top: 50px;
        padding-bottom: 50px;
        gap: 0 !important;
      }
      &--odd {
        @include respond-above('phone') {
          flex-direction: row-reverse !important;
        }
      }
      &--even {
        flex-direction: column !important;
        background-color: white;
        @include respond-above('phone') {
          flex-direction: row !important;
        }
      }
    }

    &__text {
      line-height: 1.7;

      font-size: 1.1rem;

      text-align: left;
      max-width: 28.5rem;
      margin-left: auto;
      margin-right: auto;
      padding: 1rem 1.5rem;
      margin-bottom: 1.5rem;
      @include respond-below('phone') {
        width: 100%;
      }
      @include respond-above('phone') {
        width: 50%;
      }
      h2 {
        @extend .project-h2;
      }
      a {
        font-weight: 400;
        margin-top: 0.5rem;
      }

      ul {
        margin-top: 0.5rem;
        padding-inline-start: 1em;
        margin-bottom: 1.5rem;
        li::marker {
          color: inherit;
          content: '- ';
        }
        p {
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
    }
    &__image {
      @include respond-above('phone') {
        width: 50%;

        .kpb-image__image {
          object-fit: cover;
        }
      }
    }
  }

  .call-to-action-project {
    background-color: var(--secondary);
    color: white;
    background-size: contain;

    background-position: right center;

    @include respond-below('phone') {
      background-image: none !important;
    }

    &__text {
      padding: 1rem 2.5rem;
      @include respond-above('phone') {
        margin-left: 5%;
      }

      @include respond-above('desktop') {
        padding-top: 2rem;
        padding-bottom: 3rem;
        margin-left: 10%;
      }

      h2 {
        max-width: 35rem;

        margin-top: 1.5rem;
        margin-bottom: 0.75rem;

        font-size: 1.4rem;
        @media (min-width: 544px) {
          font-size: 1.6rem;
        }
        @include respond-above('phone') {
          font-size: 45px;
        }
      }

      p {
        max-width: 30rem;
        a[data-cta='primary'] {
          padding: 0.6rem;
          font-weight: 400;
          margin-top: 1rem;
        }
      }
    }
    &__image {
      @include respond-above('phone') {
        display: none;
      }

      width: 100%;
      height: auto;
      min-height: 180px;

      .kpb-image__image {
        object-fit: cover;
        object-position: right top;
        background-color: inherit;
      }
    }
  }
}
</style>
