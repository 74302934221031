import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import API from '@/api'

import Loading from '@/views/Loading.vue'
import Project from '@/views/Project.vue'
import ProjectResidential from '@/views/ProjectResidential.vue'

Vue.use(VueMeta)
Vue.use(VueRouter)

function getRoutes() {
  switch (API.projectType) {
    case 'singular':
      return [
        {
          path: '/',
          name: 'Project',
          component: ProjectResidential,
        },
        {
          path: '*',
          redirect: {
            name: 'Project',
          },
        },
      ]
    default:
      return [
        {
          path: '/',
          name: 'Project',
          component: Project,
        },
        {
          path: '/residential/:id',
          name: 'Residential',
          props: true,
          component: () =>
            LazyLoadView(import(/* webpackChunkName: "residential" */ '@/views/Residential.vue')),
        },
        {
          path: '/embed/flatfinder',
          name: 'Flatfinder',
          props: true,
          meta: {
            navigation: false,
            footer: false,
          },
          component: () =>
            LazyLoadView(
              import(/* webpackChunkName: "flatfinder" */ '@/views/Embed/Flatfinder.vue'),
            ),
        },
        {
          path: '/embed/residentials',
          name: 'Residentials',
          props: true,
          meta: {
            navigation: false,
            footer: false,
          },
          component: () =>
            LazyLoadView(
              import(/* webpackChunkName: "residentials" */ '@/views/Embed/Residentials.vue'),
            ),
        },

        {
          path: '/kontakt',
          name: 'Contact',
          props: true,
          component: () =>
            LazyLoadView(import(/* webpackChunkName: "contact" */ '@/views/Contact.vue')),
        },
        {
          path: '/:slug',
          name: 'Post',
          props: true,
          component: () =>
            LazyLoadView(import(/* webpackChunkName: "post" */ '@/views/Post/Component.vue')),
        },
        {
          path: '*',
          redirect: {
            name: 'Project',
          },
        },
      ]
  }
}

const routes = getRoutes()

function LazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: Loading,
    delay: 150,
    // error: require('@views/_timeout.vue').default,
    // timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (from.name === 'Residential' && to.name === 'Project')
    window.sessionStorage.removeItem('flatfinder_current')

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next(Object.assign({}, to, { query: from.query }))
  } else {
    next()
  }
})

export default router
