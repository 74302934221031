import { filter, transformContent } from '@/utils'

export default function() {
  return [
    {
      component: 'Hero',
      props: {
        images: this.$path('item.media.cover'),
        class: 'header__hero',
      },
    },
    {
      component: 'Text',
      props: {
        textAlign: 'center',
        class: 'header__text',
      },
      data: {
        content: transformContent(this.$path('item.description')),
      },
    },
    {
      component: 'Section',
      condition:
        this.$path('item.customFields.section-cards.0.content') ||
        this.$path('item.customFields.section-cards.0.image'),
      props: {
        class: 'section-cards',
        width: 'full',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              class: 'section-cards__layout',
            },
            blocks: {
              default: (this.$path('item.customFields.section-cards') || []).map((item, index) => {
                return {
                  component: 'Flex',
                  props: {
                    flexDirection: 'column',
                    class: 'section-cards__content',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Image',
                        props: {
                          class: 'section-cards__image',
                        },
                        data: {
                          image: item.image,
                        },
                      },
                      {
                        component: 'Text',
                        props: {
                          class: 'section-cards__text',
                        },
                        data: {
                          content: item.content,
                        },
                      },
                    ],
                  },
                }
              }),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition:
        this.$path('item.customFields.sections.0.content') ||
        this.$path('item.customFields.sections.0.content'),
      props: {
        class: 'section',
        width: 'full',
      },
      blocks: {
        default: (this.$path('item.customFields.sections') || []).map((item, index) => {
          return {
            component: 'Flex',
            props: {
              class: `section__layout ${
                index % 2 ? 'section__layout--odd' : 'section__layout--even'
              }`,
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    class: 'section__text',
                  },
                  data: {
                    content: item.content,
                  },
                },
                {
                  component: 'Image',
                  props: {
                    class: 'section__image',
                  },
                  data: {
                    image: item.image,
                  },
                },
              ],
            },
          }
        }),
      },
    },
    {
      component: 'Banner',
      props: {
        class: 'call-to-action-project',
      },
      data: {
        backgroundImage: this.$path('item.customFields.call-to-action.image.url'),
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.call-to-action.content'),
            },
            props: {
              class: 'call-to-action-project__text',
            },
          },
          {
            component: 'Image',
            data: {
              image: this.$path('item.customFields.call-to-action.image'),
            },
            props: {
              class: 'call-to-action-project__image',
            },
          },
        ],
      },
    },
  ].filter(f => filter(f))
}
