<template>
  <div>
    <h1 class="contact-lead__title">Kontakt megler</h1>
    <p class="contact-lead__subtitle">
      Har du noen spørsmål rundt arealer, området eller leie? <br />
      Fyll inn skjema, så tar vi kontakt med deg!
    </p>
    <Lead
      class="project-lead"
      :project="item.id"
      :reference="{ onModel: 'Project', ref: item.id }"
      :fields="['!contact.upsell', '!comment', '!title']"
      :placeholders="placeholders"
      :actionLabel="'Send'"
    >
    </Lead>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  data() {
    return {
      placeholders: {
        'contact.name': 'Fyll inn navn',
        'contact.email': 'Fyll inn e-post',
        'contact.phone': 'Fyll inn telefonnummer',
        comment: '',
      },
    }
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.contact-lead {
  &__title {
    text-align: center;
    font-size: 45px;
  }
  &__subtitle {
    text-align: center;
    margin: 0 0 2rem;
  }
}

.project-lead {
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  background-color: transparent !important;

  input::placeholder {
    color: inherit;
  }

  .elder-input {
    &__label {
      display: none;
    }
    &__field {
      border: initial;
      border-radius: initial;
      border-bottom: 1px solid rgba(black, 0.5);
      background-color: inherit;

      margin: 0 0 20px;
      font-size: 18px;
      padding: 5px 0;

      &--focus {
        border-bottom: initial;
      }
    }

    &__validation {
      display: initial;
      align-items: initial;
      text-align: initial;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .elder-button {
    min-width: 200px;
    border-radius: 50px;
    background-color: var(--orange-primary);

    color: white;
    border: none;

    &:hover {
      color: white;
      background-color: var(--orange-hover);
    }

    &__label {
      //    padding: var(--vue-elder-button-padding-y, 0.75em) var(--vue-elder-button-padding-x, 1.5em) !important;
    }

    &__icon {
      //  display: none;
    }
  }

  @include respond-below('phone') {
    padding: 1rem !important;
  }

  .lead__form {
    background-color: transparent;

    &-actions {
      .elder-button--icon {
        justify-content: center;
      }
      .elder-button__label {
        flex-grow: 0;
      }
    }
  }
}
</style>
