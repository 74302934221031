import moment from 'moment'

function Moment(val, format = 'lll') {
  return moment(val).format(format)
}

function Currency(val, locale = 'nb', currency = 'NOK', decimals = 0) {
  if (!val) return
  return val.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
  })
}

function Limit(val, length) {
  if (val.length < length) return val
  return val.substring(0, length - 3) + '...'
}

export { Moment, Currency, Limit }
