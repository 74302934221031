<template>
  <div v-if="value.length" class="residentials">
    <div class="residentials__button-wrapper">
      <button
        class="residentials__button"
        @click="table = true"
        :class="[table ? 'residentials__button--active' : '']"
      >
        <span class="residentials__button-text"
          ><svg viewBox="0 0 16 16" width="16" class="residentials__button-svg">
            <path
              d="M16 2.00033H5.33333V0.666992H16V2.00033ZM16 4.00033H5.33333V5.33366H16V4.00033ZM16 7.33366H5.33333V8.66699H16V7.33366ZM16 10.667H5.33333V12.0003H16V10.667ZM16 14.0003H5.33333V15.3337H16V14.0003ZM4 0.666992H0V4.66699H4V0.666992ZM4 6.00033H0V10.0003H4V6.00033ZM4 11.3337H0V15.3337H4V11.3337Z"
            ></path>
          </svg>
          Arealtabell</span
        >
      </button>
      <button
        class="residentials__button"
        @click="table = false"
        :class="[!table ? 'residentials__button--active' : '']"
      >
        <span class="residentials__button-text">
          <svg viewBox="0 0 32 32" width="16" class="residentials__button-svg">
            <rect height="8" width="8" x="1.33301" y="1.3335"></rect>
            <rect height="8" width="8" x="12" y="1.3335"></rect>
            <rect height="8" width="8" x="22.667" y="1.3335"></rect>
            <rect height="8" width="8" x="1.33301" y="12"></rect>
            <rect height="8" width="8" x="12" y="12"></rect>
            <rect height="8" width="8" x="22.667" y="12"></rect>
            <rect height="8" width="8" x="1.33301" y="22.6665"></rect>
            <rect height="8" width="8" x="12" y="22.6665"></rect>
            <rect height="8" width="8" x="22.667" y="22.6665"></rect>
          </svg>
          Oversikt lokaler</span
        >
      </button>
    </div>

    <div class="residentials__wrapper">
      <table class="residentials__table" v-if="properties.length && table">
        <thead>
          <tr>
            <th v-for="(property, index) in properties" :key="'value_th_' + index">
              <span v-if="getPropertyLabel(property)">
                {{ getPropertyLabel(property) }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e, idx) in value" :key="'tr_' + idx" @click="onClick(e)">
            <td v-for="(property, index) in properties" :key="'value__td' + index">
              <span v-if="getProperty(e, property)">
                {{ getProperty(e, property) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!table" class="residentials-card-wrapper">
      <a
        class="residentials-mobile-card"
        v-for="(item, index) in value"
        @click="onClick(item)"
        :key="index"
      >
        <div
          :style="{ backgroundImage: 'url(' + item.media.floorplan[0].url + ')' }"
          class="residentials-mobile-card__image"
        ></div>
        <div class="residentials-mobile-card__properties">
          <p class="residentials-mobile-card__available">Ledig</p>
          <h3>{{ item.number || item.name }}</h3>
          <div>
            <svg class="residentials-mobile-card__icon" viewBox="0 0 64 64">
              <path
                d="M 62 21 H 46 V 3 c 0 -1.1 -0.4 -2 -1.5 -2 h -42 C 1.4 1 0 1.9 0 3 v 58 c 0 1.1 1.4 2 2.5 2 h 60 c 1.1 0 1.5 -0.9 1.5 -2 V 23 a 2 2 0 0 0 -2 -2 Z m -2 17 h -2 V 27.3 H 40 V 7 H 30 v 6 h -2 V 7 H 6 v 29 h 7 v 2 H 6 v 19 h 22 V 38 h -6 v -2 h 6 V 23 h 2 v 34 h 28 V 47 h 2 v 12 H 4 V 5 h 38 v 20.34 h 18 V 38 Z"
              ></path>
            </svg>
            <span
              >Areal <span>&#13217;</span>
              {{ item.properties.find(p => p.key === 'kvm').formatted }}</span
            >
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import API from '@/api'
import Bus from '@/bus'

import Residentials from '@kvass/residentials/src/Component'
import mitt from 'mitt'
import variables from '@/styles/base/_variables.scss'
const eventBus = mitt()

export { eventBus }
export default {
  data() {
    return {
      filter: {},
      project: API.project,
      table: window.innerWidth > parseFloat(variables.phone) ? true : false,
    }
  },
  props: {
    value: Array,
  },
  computed: {
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  methods: {
    onToggle(e) {
      if (!this.table) {
      }
    },

    onClick(e) {
      eventBus.emit('click', e)
      if (e.redirectUrl) return (window.location.href = e.redirectUrl)
      this.$router.push({
        name: 'Residential',
        params: { id: e.id },
        query: this.$route.query,
      })
    },

    getFloorplan(item) {
      return this.$path('media.floorplan.0', item) || {}
    },
    getProperty(item, key) {
      let match = item.properties.find(p => p.key === key)
      if (!match) return

      return match.formatted
    },

    getPropertyLabel(key) {
      let match = this.value.filter(Boolean).find(i => i.properties.find(p => p.key === key))

      if (!match) return

      let property = match.properties.find(p => p.key === key)

      return property.label || this.$t(property.key)
    },

    getProperties(item) {
      return item.properties.filter(i => i.key === ('propertType' || 'btaM'))
    },
  },
  created() {
    Bus.on('residentials:filter', filter => (this.filter = filter))
  },
  components: {
    Residentials,
  },
}
</script>

<style lang="scss">
.residentials {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 5.65093%;
  margin-bottom: 20px;
  @include respond-above('phone') {
    padding: 40px 2vh 0;
    padding-bottom: 5.65093%;
  }
  &__wrapper {
    max-width: 960px;
    margin: 0 auto;
    @include respond-below('desktop') {
      max-width: 900px;
    }

    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
  }

  &__table {
    min-width: 100%;
    border-collapse: collapse;
    --table-border: 1px solid rgba(0, 0, 0, 0.15);

    tbody {
      tr:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }

      tr {
        border-bottom: var(--table-border);

        &:last-child {
          border-bottom: unset;
        }

        &:hover {
          position: relative;
          cursor: pointer;
          -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
          transition: -webkit-box-shadow 0.2s ease-in-out;
          -o-transition: box-shadow 0.2s ease-in-out;
          transition: box-shadow 0.2s ease-in-out;
          transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
          -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
          z-index: 1;
        }
      }
    }

    td {
      padding: 11px 12px !important;
      font-size: 18px;
      line-height: 22px;
      text-align: left;
      &:empty {
        display: none;
      }
    }

    thead {
      th {
        background-color: inherit;

        text-decoration: underline;
        padding: 11px 12px !important;
        font-size: 18px;
        line-height: 22px;
        text-align: left;
        &:empty {
          display: none;
        }
      }
    }
  }

  &__button {
    &-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 auto 55px;
      max-width: 1400px;
      @include respond-below('phone') {
        justify-content: center;
      }
    }
    &:first-child {
      margin-right: 16px;
    }

    position: relative;
    padding: 0 16px;
    min-width: 133px;
    min-height: 42px;
    border: 1px solid transparent;

    border-radius: 8px;
    background: none;
    color: inherit;
    -webkit-appearance: button;
    cursor: pointer;
    outline: none;

    &--active {
      border-color: grey;
    }

    &-text {
      position: relative;
      padding-left: 27px;
      font-size: 15px;
      color: inherit;
      pointer-events: none;
    }
    &-svg {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__scroller {
    background: none;
  }

  &__header {
    display: none;
  }

  &-actions {
    margin-left: auto;
    width: max-content;

    &__item {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: 10rem;
      border: 1px solid transparent;

      &:hover {
        border-color: var(--primary-contrast);
      }
    }
  }
}
.residentials-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  padding: 0;
  margin: 0 auto;
  @include respond-below('phone') {
    flex-direction: column;
  }
}
.residentials-mobile-card {
  // margin: 40px 2vh 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  font-size: 12px;
  text-decoration: none;
  background-color: white;
  cursor: pointer;

  transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease,
    -webkit-box-shadow 0.3s ease;

  width: auto;
  max-width: 48%;
  min-width: 31%;
  flex: 1 1;
  margin: 0 1% 12px;
  padding: 0;
  @include respond-below('tablet') {
    min-width: 48%;
  }
  @include respond-below('phone') {
    min-width: 98%;
  }

  &:hover {
    transform: scale(1.04);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  }

  h3 {
    font-weight: 700;
    font-size: 15px;
    @media (max-width: 543px) {
      font-size: 17px;
    }
  }

  @media (max-width: 543px) {
    font-size: 14px;
  }

  &__image {
    @keyframes ImageTransition {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    opacity: 0;
    animation: ImageTransition 0.8s 0s forwards;

    background-color: rgb(227, 224, 216);

    min-height: 250px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &__properties {
    display: flex;
    flex-direction: column;
    padding: 0px 15px 10px 25px;
  }

  &__available {
    background-color: rgb(54, 158, 105);
    left: 0;
    right: auto;
    top: 20px;
    padding: 10px 20px;
    font-size: 11px;
    position: absolute;
    color: #fff;
    margin: 0 0 1.5rem;
  }
  &__icon {
    color: rgb(250, 128, 114);
    margin: 3px 7px 0 0;
    vertical-align: top;

    width: 12px;
    height: 12px;
    fill: currentColor;
  }
}
</style>
