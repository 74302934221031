<template>
  <div class="residential-lead">
    <h3 class="residential-lead__title">Kontakt oss og motta prospekt</h3>
    <p class="residential-lead__subtitle">
      Her kan du be om å motta prospekt og bli kontaktet av utleieansvarlig
    </p>
    <Lead
      class="residential-lead__lead"
      :project="$path('project.id', item)"
      :reference="{ onModel: 'Residential', ref: item.id }"
      :fields="['!contact.upsell', '!comment', '!title']"
      :placeholders="placeholders"
      :actionLabel="'Send'"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  data() {
    return {
      placeholders: {
        'contact.name': 'Fyll inn navn',
        'contact.email': 'Fyll inn telefonnummer',
        'contact.phone': 'Fyll inn e-post',
        comment: '',
      },
    }
  },
  computed: {
    ...mapState('Residential', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.residential-lead {
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding-top: 10vh;
  padding-bottom: 4rem;
  color: white;
  background-color: var(--secondary);

  &__title {
    max-width: 640px;
    padding: 0 10px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.18125rem;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    @media (min-width: 544px) {
      font-size: 1.35rem;
    }
    @include respond-above('phone') {
      font-size: 1.6875rem;
    }
  }

  &__subtitle {
    padding: 0 10px;
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
  }

  &__lead {
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .lead__form {
      color: white;
      background-color: var(--secondary);
    }

    input::placeholder {
      color: white;
    }

    .elder-input {
      &__label {
        display: none;
      }
      &__field {
        border: initial;
        border-radius: initial;
        border-bottom: 1px solid rgba(white, 0.5);
        color: white;
        background-color: var(--secondary);

        margin: 0 0 20px;
        font-size: 18px;
        padding: 5px 0;

        &--focus {
          border-bottom: initial;
          color: white;
        }
      }

      &__validation {
        display: initial;
        align-items: initial;
        text-align: initial;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .elder-button {
      min-width: 200px;
      border-radius: 5px;
      border: none;
      color: var(--secondary);
      background-color: var(--primary);

      &:hover {
        color: var(--secondary);
        background-color: rgb(238, 201, 39);
      }

      &__label {
        padding: var(--vue-elder-button-padding-y, 0.75em) var(--vue-elder-button-padding-x, 1.5em) !important;
      }

      &__icon {
        display: none;
      }
    }
  }
}
</style>
