<template>
  <div v-if="value.length" class="roles">
    <div class="roles__list">
      <div
        class="roles__item"
        v-for="(item, index) in value"
        :key="index"
        :data-id="item.id"
        :data-email="item.email"
      >
        <div class="roles__avatar" :style="{ backgroundImage: `url(${item.avatar})` }"></div>
        <div class="roles__content">
          <div class="roles__name">{{ item.name }}</div>
          <div v-if="item.title" class="roles__user-title">{{ item.title }}</div>

          <div style="margin-top: 0.5rem">
            <a v-if="item.phone" class="roles__phone" :href="`tel:${item.phone}`"
              ><FontAwesomeIcon :icon="['fal', 'mobile']" />{{ item.phone }}</a
            >
          </div>
          <div style="margin-top: 0.5rem">
            <a v-if="item.email" class="roles__email" :href="`mailto:${item.email}`"
              ><FontAwesomeIcon :icon="['fal', 'envelope']" />{{ item.email }}</a
            >
          </div>
          <img
            v-if="item.company"
            height="64"
            class="roles__company-logo"
            :src="item.company.logo"
            :alt="item.company.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    title: String,
  },
}
</script>

<style lang="scss">
.roles {
  padding: 2rem;
  margin-right: 5rem;

  @include respond-below('phone') {
    padding: 1rem;
    margin-right: 0;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  &__title {
  }

  &__avatar {
    width: 250px;

    background-size: cover;
    background-position: center;

    height: 440px;
    max-width: 250px;
    max-height: 250px;
    border-radius: 50%;
  }

  &__subtitle {
    color: rgba($dark, 0.6);
  }

  &__user-title {
  }

  &__item {
    display: flex;
    align-content: flex-end;
    flex-flow: column wrap;
    gap: 1.5rem;

    text-align: center;

    @include respond-below('phone') {
      align-content: center;
    }
  }

  &__name {
    font-size: 1.5rem;
    color: #ae8a65;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }

  &__company {
    &-logo {
      margin-top: 1rem;
    }
  }

  &__email {
    white-space: nowrap;

    margin-right: 10px;
    svg {
      margin-right: 7px;
    }
  }
  &__phone {
    text-decoration: none;
    white-space: nowrap;

    margin-right: 10px;

    svg {
      margin-right: 7px;
    }
  }
}
</style>
